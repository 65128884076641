.mainn{
    background-color: aliceblue;
    display: block;
    min-height: 100%!important;
    padding-bottom: 200px;
    padding-top: 50px;
    position: relative;
    text-align: center;
    width: 100%;
} 
.process-wrap{
    min-height: 100%;
    text-align: center;
    overflow-x: hidden;
    margin: 0 auto;
    max-width: 960px;
}
.process-section-1{
    height: 257px;
    padding-top: 83px;
}
.process-left-quote{
    margin-left: 15px;
    opacity: 1; 
    margin-top: 65px;
    position: absolute;
    /* width: 340px; */
    text-align: right;
    background-color: aliceblue;
    z-index: 5;
}

.process-left-data{
    margin-left: 15px;
    opacity: 1; 
    margin-top: 65px;
    position: absolute;
    /* width: 340px; */
    text-align: right;
    background-color: aliceblue;
    z-index: 5;
}

.data{
    margin-left: 15px;
    opacity: 1; 
    margin-top: 65px;
    position: absolute;
    text-align: right;
    background-color: aliceblue;
    z-index: 5;
    /* margin-top: -34px ;
    width: 310px; */
}

.blockquote{
    font-size: 16px;
    font-weight: 400;
    /* line-height: 30px; */
}
.circle{
    display: block;
    height: 150px;
    left: 50%;
    margin-left: -75px;
    margin-top: 0!important;
    position: absolute;
    width: 150px!important;
    z-index: 7!important;
}
.process-circle{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 150px;
    margin-left: 0px;
    margin-top: 54px;
    opacity: 1;
    width: 150px;
    /* background-color: rgb(58, 153, 211); */
    border-radius: 100px;
    /* display: block; */
    border-radius: 100px;
    text-align: left;
    background: radial-gradient(circle at 30% 5%, white 1px, white .5%, #ff9f18 30%, #ffa91e 100%);
}
.image-resize{
    width: 50%;
    height: auto;
}
.bulb-icon{
    margin-left: 29px;
    margin-top: 29px;
    position: absolute;
    /* color: #fff; */
    color: black;
}
.process-right{
    margin-left: 632px;
    opacity: 1;
    margin-top: -44px!important;
    z-index: 5;
    background-color: aliceblue;
}
.process-right-last{
    margin-left: 632px;
    opacity: 1;
    margin-top: -44px!important;
    z-index: 5;
    background-color: aliceblue;
}
.first-section-paragraph{
    width: 311px;
    text-align: left;
    font-family: Poppins Light;
}

.second-section-paragraph{
    width: 311px;
    text-align: left;
    margin-top: 135px;
    font-family: Poppins Light;
}

.testing-describe{
    width: 311px;
    text-align: left;
    font-family: Poppins Light;
}

.process-heading{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 120px;
    color: #000;
    display: block;
    font-size: 22px;
    /* font-family: Nunito; */
    text-align: left;
}
.process-paragraph{
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    font-family: 'Poppins Light';
}
.process-section-2{
    height: 250px;
    padding-top: 100px;
}
.last-paragraph{
    width: 311px;
    text-align: left;
    margin-top: 130px;
    font-family: Poppins Light;
}

@media(max-width: 950px){
.mainn{
  padding-bottom: 40px;
}
.circle{
    margin-left: 0px;
    position: unset;
}
/* .process-wrap{
    overflow: hidden;
} */
.process-left-quote{
    margin-left: 15px;
    opacity: 1;
    text-align: center;
    position: relative;
}
/* .process-left-quote-1{
    margin-top: 0px;
} */
.process-right{
    margin-left: 0px;
    margin-top: 0px!important;
    /* width: 40%; */
    padding-top: 20px;
}
.process-right-last{
    margin-left: 0px;
    margin-top: 0px!important;
    /* width: 40%; */
    /* padding-top: 20px; */
}
.first-section-paragraph{
    text-align: center;
}
.second-section-paragraph{
    margin-top: 0px;
    margin-left: 30px;
    text-align: center;
}
.process-left-quote{
    /* margin-top: 0px; */
}
.process-left-data{
    position: relative;
    margin-top: 0px;
    /* width: 0px; */
}
.process-circle{
    margin-top: 0px;
}
.testing-describe{
    text-align: center;
}
.process-section-1{
    padding-top: 0px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    height: 10%;
    justify-content: space-evenly;
    align-items: center;
    /* gap: 30px; */
}
.first-left-data{
  padding-right: 0px;
}
.process-heading{
    text-align: center;
    margin-top: 0px;
    font-family: Poppins ExtraBold;
}
.process-paragraph{
    text-align: center;
    font-family: 'Poppins Light';
}
.last-paragraph{
    text-align: center;
    margin-top: 20px;
}
}

