.feature-main{
  /* height: 100vh; */
  background-color:#ffd761;
}

.icon-style {
  background-color: #ff9f18;
  box-shadow: 40px 30px 0 -15px #ff9f18, -60px 40px 0 -30px #ff9f18, -100px 0 0 -50px #ff9f18, 100px 0 0 -50px #ff9f18;
  display: block;
  height: 128px;
  left: 50%;
  margin-left: -114px;
  margin-top: -124px;
  position: absolute;
  width: 128px; 
  border-radius: 200px;
  overflow: hidden;
  padding: 50px;
  z-index: 2;
}   

@keyframes swinging {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}


.icon-style {
  /* transform-origin: 65.15% 0%; */
  animation: swinging 1.5s ease-in-out infinite; /* Adjust the animation duration and properties as needed */
}


.icon{
  height: 128px;
  margin-left: 0px;
  margin-top: 0px;
  opacity: 1;
  width: 128px;
}

.wrap {
  min-height: 100%;
  text-align: center;
  overflow-x: hidden;
  margin: 0 auto;
  /* max-width: 960px; */
  /* background-color: #2c3e50; */
  max-width: 100vw;
  /* padding-left: 15%;
  padding-right: 15%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}


.feature-main-heading{
color: black;
margin-left: 10px;
margin-right: 10px;
margin-top: 155px;
}

.feature-main-paragraph{
  color: black;
  font-size: 22px;
}

.child-wrapper{
  padding-bottom: 5%;
}

.first-section-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.first-left-data{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 405px;
  height: 161px;
  gap: 20px;
  padding: 10px;
  padding-right: 100px;
}

.text-main{
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 17px;
}

.mobile-heading{
  margin: 0px;
  color: black;
  font-size: 24px;
  font-family: Poppins ExtraBold;
}

.mobile-para{
  color: black;
  font-family: Poppins Light;
  margin: 0px;
  text-align: left;
}

.second-right-data{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 405px;
  height: 161px;
  gap: 20px;
  padding: 10px;
}

.text-main-align{
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 17px;
  margin-bottom: 10px;
}


@media(max-width: 950px){
.feature-main{
  height: auto;
}

.first-section-container{
  justify-content: center;
}

.first-icon{
  margin-top: 15px;
  color: rgba(255,255,255,.25);
  margin-left: 0px;
}

.first-left-data{
  padding-right: 0px;
}

}