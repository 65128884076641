.main {
  /* background-image: url('../../assets/images/bg.png'); */
  /* background: linear-gradient(to bottom, #f89a2b, #ffd563, #f89a2b); */
  background-image: linear-gradient(to bottom right, #ffd761, #fffcf2);
  /* background-color: #f89a2b; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  height: 100vh;
}
.twitter {
  background-image: url("../../assets/images/x-twitter.png");
}
.pipe {
  /* background-image: url('../../assets/images/rope.png'); */
  background: #ff9f18;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  /* height: 285px; */
  height: 50%;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 0;
  width: 10px;
  position: fixed;
  z-index: 2;
}

.left-image {
  display: flex;
  flex-direction: column;
}
.positionFixed {
  background: #ff9f18;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 2380px;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 0;
  width: 10px;
  /* position: fixed; */
  z-index: 2;
}

.right-image {
  height: 100%;
}

.fire-icon {
  /* -webkit-transform: rotate(180deg); */
  color: rgba(255, 25, 25, 0.75);
  font-size: 34px;
  /* margin-top: -26px; */
  /* opacity: 0; */
  /* position: relative; */
  /* z-index: 7; */
}

.transition {
  position: relative;
  cursor: pointer;
  color: transparent;
  font-size: 46px;
  left: 50%;
  margin-left: -32px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  white-space: nowrap;
  width: 70px;
  transition: color 0.3s ease, margin-left 0.3s ease, width 0.3s ease;
}

.transition:hover {
  color: #fff;
  margin-left: -149px;
  width: 298px !important;
}

.transition i {
  color: #fafafa;
  margin-right: 20px;
  margin-top: 0;
}

.iconss-dolox:before {
  background-image: url("../../assets/images/Logo.png");
}

.laptop {
  background-image: url("../../assets/images/laptop.png");
  background-size: 100%;
  height: 354px;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin: auto;

  position: absolute;
  width: 627px;
  z-index: 9;
}

.laptop-screen {
  /* background:crimson;; */
  height: 287px !important;
  left: 50%;
  margin-left: -230.5px;
  margin-top: -172px;
  overflow: hidden;
  position: absolute;
  text-align: left;
  top: 50%;
  /* white-space: nowrap; */
  text-align: center;
  width: 461px;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.laptop-social {
  left: 50%;
  margin-left: -231px;
  margin-top: 43px;
  position: absolute;
  top: 50%;
  z-index: 99;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 480px;
}

.laptop-camera {
  background: #2c3033;
  border-radius: 10px;
  display: block;
  height: 10px;
  left: 50%;
  margin-left: -5px;
  margin-top: -170px;
  overflow: hidden;
  position: absolute;
  /* top: 52.5%; */
  width: 10px;
  z-index: 10;
}
.laptop-camera:hover {
  background-color: green;
}
.type-animation {
  font-size: 2em;
  display: block;
  color: black;
}

.scroll-down-message {
  font-size: 15px;
}

.social-icon {
  stroke-width: 0;
  height: 40px;
  width: 40px;
  color: black;
  opacity: 0.3;
}
.social-icon:hover {
  opacity: 0.7;
}

.rocket {
  top: 50%;
  transform: rotate(180deg);
  color: rgba(0, 0, 0, 0.098);
  /* position: fixed; */
  z-index: 1;
  display: block;
  position: fixed;
  margin-left: 45%;
  margin-top: 32px;
  padding: 0;
  height: 128px;
  font-size: 128px;
  width: 128px;
  z-index: 2;
}

@keyframes rotateRocket {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Add this class to your .rocket element to apply the animation */
.rotate-rocket {
  animation: rotateRocket 1s linear;
  animation-fill-mode: forwards;
  z-index: 999;
  color: white;
  position: fixed;
}

.line {
  display: none;
}

.rocket::after {
  top: 70%;
  transform: rotate(90deg);
  color: rgba(0, 0, 0, 0.098);
  /* position: fixed; */
  z-index: 1;
  display: block;
  position: fixed;
  margin-left: 45%;
  margin-top: 32px;
  padding: 0;
  height: 128px;
  font-size: 128px;
  width: 128px;
  z-index: 1;
}

/* .rocket-fire-icon{
    color: rgba(255,243,58,.95);
    margin-top: -51px;
    position: relative;
    z-index: 7;
    height: 32px;
    font-size: 30px;
    width: 32px;
    display: block; 
    margin-left: 49px;
} */

.rocket-fuel {
  display: block;
  /* animation: pulsate 1s ease-out infinite;  */
  /* background: rgba(0,0,0,.25); */
  border-radius: 20px;
  box-shadow: 10px 5px 0 2px rgba(0, 0, 0, 0.25),
    -10px 5px 0 2px rgba(0, 0, 0, 0.25), 0 10px 0 5px rgba(0, 0, 0, 0.25);
  content: " ";
  /* display: none; */
  left: 50%;
  height: 15px;
  margin-left: -8px;
  margin-top: -35px !important;
  /* position: absolute; */
  width: 15px;
  z-index: 6 !important;
  opacity: 1 !important;
  position: relative;
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 10px 5px 0 0 rgba(0, 0, 0, 0.2),
      -10px 5px 0 0 rgba(0, 0, 0, 0.2), 0 10px 0 0 rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 10px 5px 0 0px rgba(0, 0, 0, 0),
      -10px 5px 0 0px rgba(0, 0, 0, 0), 0 10px 0 0px rgba(0, 0, 0, 0);
  }
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* @media (min-width: 1024px){
    .pipe{
        height: 60%;
    }
    .positionFixed{
        height: 2720px;
    }
    .right-image{
        width: 280px;
    }
    } */

@media (min-width: 1025px) {
    .pipe{
        height: 43%;
    }
  .positionFixed {
    height: 2290px;
  }
}

@media (max-width: 950px) {
  .laptop {
    background-size: 100%;
    height: 243px;
    left: 50%;
    margin-left: -223.5px;
    margin-top: -195px;
    position: absolute;
    top: 50%;
    width: 430px;
    z-index: 9;
  }
  .positionFixed {
    height: 2690px;
  }

  .laptop-social {
    left: 50%;
    margin-left: -167.5px;
    margin-top: -36px;
    position: absolute;
    top: 50%;
    z-index: 99;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 326px;
  }

  .laptop-screen {
    height: 183px !important;
    left: 50%;
    margin-left: -166.5px;
    margin-top: -178px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    text-align: center;
    width: 318px;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .type-animation {
    font-size: 1.5em !important;
  }
  .laptop-camera {
    border-radius: 10px;
    display: block;
    height: 7px;
    left: 50%;
    margin-left: -12px;
    margin-top: -190px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 7px;
    z-index: 10;
  }
  .laptop-camera:hover {
    background-color: green;
  }
  .social-icon {
    height: 25px;
    width: 22px;
    color: black;
    opacity: 0.3;
  }
  .social-icon:hover {
    opacity: 0.7;
  }
  .rocket {
    margin-left: 41%;
  }
}

@media (max-width: 480px) {
  .laptop {
    height: 173px;
    width: 308px;
    margin-left: -158.5px;
    margin-top: -92px;
  }
  .right-image {
    display: none;
  }
  .laptop-screen {
    height: 143px !important;
    margin-left: -120.5px;
    margin-top: -89px;
    width: 230px;
  }
  .left-image {
    height: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 150px;
  }
  .laptop-camera {
    margin-top: -89px;
    margin-left: -10px;
  }
  .laptop-social {
    margin-left: -134.5px;
    margin-top: 16px;
    width: 258px;
  }
}
