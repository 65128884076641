.main-about{
    height: 100vh;
    /* background-image: url('../../assets/images/map.png'); */
    position: relative;
    /* background: (rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url('../../assets/images/map.png') top center; */
    display: block;
    min-height: 100%!important;
    padding-bottom: 200px;
    padding-top: 50px;
    position: relative;
    text-align: center;
    width: 100%;
}

.main-about::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1; /* Place the background color behind the content */
    background-image: url('../../assets/images/ottawa4-1.png');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    opacity: 0.5;
  }



.about-icon-style {
    background-color:#ff9f18;
    box-shadow: 40px 30px 0 -15px #ff9f18, -60px 40px 0 -30px #ff9f18, -100px 0 0 -50px #ff9f18, 100px 0 0 -50px #ff9f18;
    display: block;
    height: 128px;
    left: 50%;
    margin-left: -114px;
    margin-top: -124px;
    position: absolute;
    width: 128px; 
    border-radius: 200px;
    overflow: hidden;
    padding: 50px;  
} 

.about-icon{
    height: 128px;
    margin-left: 0px;
    margin-top: 0px;
    opacity: 1;
    width: 128px;
}


@keyframes swinging {
    0% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(2deg);
    }
  }

.about-icon-style {
    /* transform-origin: 65.15% 0%; */  
    animation: swinging 1.5s ease-in-out infinite; /* Adjust the animation duration and properties as needed */
  }


  .about-wrap{
    min-height: 100%;
    text-align: center;
    overflow-x: hidden;
    margin: 0 auto;
    max-width: 960px;
  }
  .about-main-heading{
    margin-left: 0px;
    opacity: 1;
    color: #000;
    margin-right: 10px;
    margin-top: 150px;
    font-size: 39px;
  }
  .about-heading{
    margin-left: 0px;
    opacity: 1;
    color: #ff9f18;
    font-size: 22px;
    margin-top: -20px;
  }
  #point{
    background: #ff9f18;
    border-radius: 50px;
    box-shadow: inset 0 0 25px rgba(0,0,0,.25), 0 5px 0 3px  rgba(255,159, 24, 1), 0 10px 0 10px rgba(0,0,0,.75);
    content: ' ';
    display: block;
    float: right;
    height: 50px;
    margin-left: 770px;
    margin-top: 305px;
    position: absolute;
    width: 50px;
    transform: translate(-50%, -50%);
    /* animation: radar 1s linear infinite;
    -webkit-animation: radar 7s linear infinite; */
  }

  .radar{
    animation: radar 1s linear infinite;
    -webkit-animation: radar 7s linear infinite;
  }

  /* .radar {
    -webkit-animation: radar 7s linear infinite;
    animation: radar 7s linear infinite
} */

@keyframes radar {
    0% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)
    }
    25% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 100px rgba(0, 0, 0, .25), 0 0 0 0 rgba(0, 0, 0, .25), 0 0 0 430px rgba(0, 0, 0, .1)
    }
    50% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px  rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 200px rgba(0, 0, 0, 0), 0 0 0 100px rgba(0, 0, 0, 0), 0 0 0 960px rgba(0, 0, 0, 0)
    }
}

@-webkit-keyframes radar {
    0% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px  rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0)
    }
    15% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px  rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 100px rgba(0, 0, 0, .25), 0 0 0 0 rgba(0, 0, 0, .25), 0 0 0 430px rgba(0, 0, 0, .1)
    }
    50% {
        box-shadow: inset 0 0 25px rgba(0, 0, 0, .25), 0 5px 0 3px  rgba(255,159, 24, 1), 0 10px 0 10px rgba(0, 0, 0, .75), 0 0 0 200px rgba(0, 0, 0, 0), 0 0 0 100px rgba(0, 0, 0, 0), 0 0 0 960px rgba(0, 0, 0, 0)
    }
}
  .hatch{
    background: rgba(0,0,0,.75);
    border-radius: 5px;
    float: right;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-top: 120px;
    padding: 20px;
    position: relative;
    width: 320px;
    z-index: 2;
    animation: swinging 1s ease-in-out infinite;
    /* -webkit-animation: swinging 2s ease-in-out infinite; */
    transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
  }

  @keyframes hatch {
    0% {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }
    50% {
        box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
        opacity: 0.4;
      }
    100% {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }
  }

  .heading-content{
    color: #ccc;
    display: inline-block;
    margin-bottom: 10px;
    text-align: right;
    width: 100px;
  }
  .company-content{
    color: #fafafa;
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: left;
    width: 200px;
  }
  .hatch::after{
    content: ' ';
    border: solid transparent;
    border-top-color: rgba(0,0,0,.75);
    border-width: 20px;
    display: block;
    height: 0;
    margin-left: 130px;
    margin-top: 20px;
    position: absolute;
    width: 0;
  }

.company-desc{
    position: relative;
    display: block;
    color: #111;
    font-size: 28px;
    letter-spacing: -1px;
    line-height: 47px;
    text-align: left;   
}

.paragraph-styling{
    background: #ff9f18;
    color: #fafafa;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
.fade-color{
    color:#4d4c4c;
}



.tossing {
  animation: tossing 2.5s ease-in-out infinite;
  -webkit-animation: tossing 2.5s ease-in-out infinite
}

@keyframes tossing {
  0% {
      transform: rotate(-4deg)
  }
  50% {
      transform: rotate(4deg)
  }
  100% {
      transform: rotate(-4deg)
  }
}

@-webkit-keyframes tossing {
  0% {
      -webkit-transform: rotate(-4deg) translate3d(0, 0, 0)
  }
  50% {
      -webkit-transform: rotate(4deg) translate3d(0, 0, 0)
  }
  100% {
      -webkit-transform: rotate(-4deg) translate3d(0, 0, 0)
  }
}

@media (min-width: 1025px) {
 .main-about{
  padding-bottom: 250px;
 }
}

@media(max-width : 950px){
  .main-about{
    height: auto;
  }
  .about-wrap{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }
  #point{
    margin-left: 0px;
    margin-top: 269px;
    position: relative;
  }
  .hatch{
    margin-left: 0px;
    margin: 0 auto; 
     margin-bottom: 0px;
     margin-top: -279px;
  }
  .company-desc{
    margin-top: 90px;
    padding: 10px;
  }
}